import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "crashlytics-by-firebase"
    }}>{`Crashlytics (by `}<a parentName="h1" {...{
        "href": "https://firebase.google.com/"
      }}>{`Firebase`}</a>{`)`}</h1>
    <p>{`L'application mobile Galidog intègre la solution `}<a parentName="p" {...{
        "href": "https://firebase.google.com/products/crashlytics"
      }}>{`Crashlytics`}</a>{` afin de collecter, analyser et organiser les rapports de plantage de l'application.`}</p>
    <p>{`Pour cela, il était nécessaire de « linker » l'application à un compte Firebase. Tout est donc déjà configuré ! Mais il sera nécessaire de bien penser à récupérer le fichier `}<inlineCode parentName="p">{`google-services.json`}</inlineCode>{` du compte Firebase en question `}<strong parentName="p">{`à chaque initialisation`}</strong>{` `}<a parentName="p" {...{
        "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-frontend"
      }}>{`du projet mobile`}</a>{` et de le placer dans le répertoire `}<inlineCode parentName="p">{`android/app`}</inlineCode>{` du projet (disponible sous le `}<a parentName="p" {...{
        "href": "https://podio.com/"
      }}>{`Podio`}</a>{` de `}<a parentName="p" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{`).`}</p>
    <blockquote>
      <p parentName="blockquote">{`La console Firebase du projet est ici : `}<a parentName="p" {...{
          "href": "https://console.firebase.google.com/u/0/project/newagent-495ab/overview"
        }}>{`https://console.firebase.google.com/u/0/project/newagent-495ab/overview`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      